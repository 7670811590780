import { Injectable } from '@angular/core';
import { AppConfigsService } from '../app-configs/app-configs.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  API_URLS;
  constructor(
    public configs: AppConfigsService,
    private http: HttpClient
  ) {
    this.API_URLS = this.configs.env.apiUrl;
  }
  filterAPI(path: string, body: any) {
    return this.http.post(`${this.API_URLS}/${path}/filter`, body).toPromise();
  }
  advanceFilterAPI(path: string, body: any) {
    return this.http.post(`${this.API_URLS}/${path}/advance-filter`, body).toPromise();
  }
  searchAPI(path: string, body: any) {
    return this.http.post(`${this.API_URLS}/${path}/search`, body).toPromise();
  }
  single(path: string, id: number | string) {
    return this.http.get(`${this.API_URLS}/${path}/${id}`).toPromise();
  }
  list(path: string) {
    return this.http.get(`${this.API_URLS}/${path}`).toPromise();
  }
  getList(path: string, body: any) {
    return this.http.post(`${this.API_URLS}/${path}`, body).toPromise();
  }
  update(path: string, body: any) {
    return this.http.put(`${this.API_URLS}/${path}/${body.id}`, body).toPromise();
  }
  delete(path: string, id: number | string) {
    return this.http.delete(`${this.API_URLS}/${path}/${id}`).toPromise();
  }
  create(path: string, body: any) {
    return this.http.post(`${this.API_URLS}/${path}`, body).toPromise();
  }
  other(path: string, id: number | string, body: any, param: number | string = '') {
    return this.http.post(`${this.API_URLS}/${path}/${id}${param ? `/${param}` : ''}`, body).toPromise();
  }

  downloadFile(path: string, id: number | string, body: any) {
    return this.http.post(`${this.API_URLS}/${path}/${id}`, body, { responseType: 'blob' as 'blob' }).toPromise();
  }

  satelliteJson(body: any) {
    return this.http.get(`./assets/satellite-json/${body.sector}_${body.year}_CO2_ff.json`).toPromise();
  }
  

  uploadLogo(path: string, body: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.request(new HttpRequest('POST', `${this.API_URLS}/${path}/upload`, body, {
      headers,
      reportProgress: true
    })).toPromise();
  }
}
