import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Dashboard';

  supportLanguage = ['en', 'fr', 'hi', 'sp'];
  language: string;

  constructor(private translateService:TranslateService) {
    this.translateService.addLangs(this.supportLanguage);
    this.translateService.setDefaultLang('en');
    if (localStorage.getItem("language") === null) {
      localStorage.setItem('language', 'en');
    }
    this.language = localStorage.getItem('language');
    this.translateService.use(this.language);
  }
}
