import { Injectable } from '@angular/core';
import { AppConfigsService } from '../app-configs/app-configs.service';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppInterceptorService {

  constructor(
    public configs: AppConfigsService,
    private auth: AuthService,
    private router: Router
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = localStorage.getItem(this.configs.localStorage.accessToken);
    if (!!accessToken) {
      this.auth.lastInteractionSession(); // check every time that user is idle for long time.
      // clone and modify the request
      request = request.clone({
        setHeaders: { [this.configs.localStorage.accessToken]: accessToken }
      });
      // add a header for multilingual
      // const customReq = request.clone({
      //   headers: request.headers.set()
      // });
    }

    return next.handle(request).pipe(
      tap((response: HttpEvent<any>) => {
        if (response instanceof HttpResponse) {

          if (response.status === 401) {
            localStorage.clear();
            this.router.navigate(['/']);
          }
        }
      }, (error) => {
        if (error.status === 0) {
          error.error = {
            errors: [{
              // msg: this.translate.instant('LOAD.ERROR.UNABLE_TO_CONNECT'),
              param: null
            }]
          };
        }
      })
    );
  }
}
