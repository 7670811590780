(function (window) {
    window.__env = window.__env || {};

    //localhost
    // window.__env.apiUrl = 'http://dashboard.bitlumens.com/api/';
    // window.__env.uploadsUrl = 'http://localhost:4200/uploads/';

    // window.__env.apiUrl = 'http://localhost:9128/api';
    window.__env.apiUrl = 'https://users.bitlumens.com/api';
    window.__env.uploadsUrl = '/uploads/';

    // Whether or not to enable debug mode
    // Setting this to false will disable console output
    window.__env.enableDebug = true;
    // console.log(window);
}(this));