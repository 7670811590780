import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private auth: AuthService
  ) {

  }
  canActivate() {
    return this.auth.isAuthentication();
  }
  canActivateChild() {
    return this.auth.isAuthentication();
  }
}
