import { Injectable } from '@angular/core';
import { AppConfigsService } from '../app-configs/app-configs.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  API_URLS;
  constructor(
    public configs: AppConfigsService,
    private router: Router,
    private http: HttpClient
  ) {
    this.API_URLS = this.configs.env.apiUrl;
  }
  isLoggedIn() {
    const accessToken = this.getToken();
    if (!!accessToken) {
      return true;
    } else {
      this.router.navigate(['/']);
    }
  }
  isAuthentication() {
    const accessToken = this.getToken();
    if (!!accessToken) {
      return true;
    }
    this.router.navigate(['/auth']);
    return false;
  }
  getToken() {
    return localStorage.getItem(this.configs.localStorage.accessToken);
  }
  lastInteractionSession() {
    const lastInterection = JSON.parse(localStorage.getItem(this.configs.localStorage.lastInteraction)) || 0;
    if ((Date.now() - lastInterection) > this.configs.idleTime) {
      return false;
    }
    this.setLastInterationSession();
  }
  setLastInterationSession() {
    localStorage.setItem(this.configs.localStorage.lastInteraction, JSON.stringify(Date.now()));
  }
  signin(body) {
    return this.http.post(this.API_URLS + '/auth/signin', body).toPromise();
  }
  signup(body) {
    return this.http.post(this.API_URLS + '/auth/signup', body).toPromise();
  }
  forgot(body) {
    return this.http.post(this.API_URLS + '/auth/forgot', body).toPromise();
  }
  reset(body) {
    return this.http.post(this.API_URLS + '/auth/reset', body).toPromise();
  }
  validate(body) {
    return this.http.post(this.API_URLS + '/auth/validate', body).toPromise();
  }
  signout() {
    return this.http.post(this.API_URLS + '/auth/signout', {}).toPromise();
  }
}
